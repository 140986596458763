import styled from "styled-components";

export const BasicTextModuleStyles = styled.section`
  position: relative;
  background: linear-gradient(to right, #2c3e50, #4b626c); // dark gray gradient
  padding: var(--gap); // adjust padding as needed

  .container {
    position: relative;
    padding: var(--gap);
    z-index: 2; // Ensure content is above the background

    @media (min-width: 768px) {
      > div {
        width: 66.666%;
        max-width: 700px;
      }
    }

    // Elements inside the container
    > * {
      position: relative;
      z-index: 3;
    }
  }
`;