import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import Features from "../components/Features/Features"
import LatestPosts from "../components/Post/LatestPosts"
import Contact from "../components/Contact/Contact"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"

const Index = () => {
  return (
    <>
      <Seo title="Domů" />
      <Layout>
        <BannerModule
          title="Mgr. Jan Růžek"
          subTitle="Fitness trenér, výživový a nutriční specialista, masér."
        />
        <Features
          title="Co dělám?"
          introduction="S pozorností pečují o své klienty, nabízím individuální péči a neustále rozvíjím svou kvalifikaci, abych vám mohl poskytnout ty nejlepší služby"
        />
        <BasicTextModule
          title="Málokdo to ví, ale pořádám špičkové fitness kempy..."
          content="Zaujalo tě to? Klikni víc a dozvíš se všecko!"
          link="/fitness-kempy"
          linkText="Více"
        />
        <Contact
          title="Kontakt"
          introduction="Pokud máte zájem o mé služby, nebo máte jakékoliv dotazy, neváhejte mě kontaktovat."
        />
        <LatestPosts title="Poslední příspěvky" />
      </Layout>
    </>
  )
}

export default Index
